import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { Search as SearchIcon } from '@wix/wix-ui-icons-common/on-stage';

import { EGroupsNamespace } from 'store/groups/constants';
import { selectGroups } from 'store/selectors';

import { useController } from 'common/context/controller';
import { useDidUpdate } from 'common/hooks';

import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { InputDialog } from 'wui/InputDialog';
import { TextField } from 'wui/TextField';
import { Box } from 'wui/Box';

import { SELECT_GROUP_MODAL_ROOT } from './dataHooks';
import { RecommendedGroups } from './RecommendedGroups';

import styles from './SelectGroupDialog.scss';

interface IProps extends React.ComponentProps<typeof InputDialog> {
  onSelect(groupId: string): void;
}

export function SelectGroupDialog(props: IProps) {
  const { onSelect, ...rest } = props;
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const { experiments } = useExperiments();
  const { groups$ } = useController();

  const [query, setQuery] = React.useState<string>('');
  const { groups, status } = useSelector(
    selectGroups(EGroupsNamespace.RECOMMENDED_DIALOG),
  );

  const searchGroups = React.useCallback(debounce(queryGroups, 300), []);

  useEffect(() => {
    if (props.isOpen) {
      queryGroups('');
    }
  }, [props.isOpen]);

  useDidUpdate(() => {
    searchGroups(query);
  }, [query]);

  return (
    <InputDialog
      {...rest}
      className={styles.root}
      aria-labelledby="sg-dialog-title"
      paperProps={{
        className: styles.paper,
        'data-hook': SELECT_GROUP_MODAL_ROOT,
      }}
    >
      <DialogTitle
        id="sg-dialog-title"
        title={t('groups-web.select-group-modal.title')}
        subtitle={
          <Box paddingTop="SP6" direction="vertical">
            <TextField
              value={query}
              withClearButton
              onClear={handleReset}
              prefix={<SearchIcon />}
              placeholder={t('groups-web.search.placeholder')}
              onChange={handleQueryChange}
            />
          </Box>
        }
      />
      <DialogContent
        disableSideGutters={isMobile}
        data-loading={!!status.fetch?.loading}
      >
        <RecommendedGroups
          title={query}
          onSelect={onSelect}
          onReset={handleReset}
          onLoadMore={handleLoadMore}
        />
      </DialogContent>
    </InputDialog>
  );

  function handleReset() {
    setQuery('');
  }

  function handleQueryChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQuery(event.target.value);
  }

  function handleLoadMore() {
    if (!status.fetchMore?.loading) {
      queryGroups(query, groups.length);
    }
  }

  function queryGroups(query: string, offset?: number) {
    groups$.query({
      offset,
      title: query,
      limit: isMobile ? 15 : 10,
      permissionsFilter: {
        canCreatePosts: true,
      },
      namespace: EGroupsNamespace.RECOMMENDED_DIALOG,
    });
  }
}

SelectGroupDialog.displayName = 'SelectGroupDialog';
