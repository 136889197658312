import React, { useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { SwitchTransition } from 'react-transition-group';
import { Trans, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import {
  selectIsGroupSecret,
  selectSuggestedMembers,
  selectSuggestedMembersMetadata,
} from 'store/selectors';

import { useController } from 'common/context/controller';
import {
  ProfileListItem,
  ProfileListItemSkeleton,
} from 'common/components/ProfileListItem';

import { List } from 'wui/List';
import { Fade } from 'wui/Fade';
import { EmptyState } from 'wui/EmptyState';
import { ErrorState } from 'wui/ErrorState';

interface ISuggestedMembersProps {
  groupId: string;
  selected: string[];
  search?: string;

  onSelect(memberIds: string[]): void;
}

export function SuggestedMembersList(props: ISuggestedMembersProps) {
  const { selected, search, groupId, onSelect } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const { members$ } = useController();

  const members = useSelector(selectSuggestedMembers);
  const meta = useSelector(selectSuggestedMembersMetadata);
  const isSecret = useSelector(selectIsGroupSecret(groupId));

  const querySuggested = React.useCallback(
    _.debounce((search: string) => {
      members$.querySuggested(groupId, { nickname: search });
    }, 300),
    [groupId],
  );

  useEffect(() => {
    onSelect([]);
    querySuggested(search || '');
  }, [search, querySuggested]);

  return (
    <SwitchTransition>
      {(() => {
        if (meta.error) {
          return (
            <Fade key="error">
              <ErrorState
                wired
                variant="section"
                onRetry={handleRetry}
                title={t('groups-web.discussion.error-state.title')}
                actionLabel={t('groups-web.discussion.error-state.cta')}
                subtitle={
                  <Trans
                    i18nKey="groups-web.discussion.error-state.owner.message"
                    components={[
                      <span key={0} />,
                      <br key={1} />,
                      <span key={2} />,
                    ]}
                  />
                }
              />
            </Fade>
          );
        }

        if (meta.loading) {
          return (
            <Fade key="loading">
              <List disablePadding>
                <ProfileListItemSkeleton withIcon />
                <ProfileListItemSkeleton withIcon />
                <ProfileListItemSkeleton withIcon />
                <ProfileListItemSkeleton withIcon />
                <ProfileListItemSkeleton withIcon />
              </List>
            </Fade>
          );
        }

        if (!members.length) {
          return (
            <Fade key="empty-state">
              <EmptyState
                variant="section"
                subtitle={t(getEmptyStateKey(isSecret, search))}
                title={search ? undefined : t('group-web.members.empty')}
              />
            </Fade>
          );
        }

        return (
          <Fade key="members-list">
            <InfiniteScroll
              initialLoad={false}
              useWindow={false}
              hasMore={meta.metadata.total! > members.length}
              loadMore={handleLoadMore}
              threshold={200}
              loader={
                <>
                  <ProfileListItemSkeleton withIcon />
                  <ProfileListItemSkeleton withIcon />
                </>
              }
            >
              <List disablePadding>
                {members.map((member) => (
                  <ProfileListItem
                    key={member.memberId}
                    profile={member}
                    avatarProps={{ size: 'medium' }}
                    selected={isMemberSelected(member.memberId as string)}
                    onClick={handleSelect(member.memberId as string)}
                    subtitle={null}
                  />
                ))}
              </List>
            </InfiniteScroll>
          </Fade>
        );
      })()}
    </SwitchTransition>
  );

  function handleSelect(memberId: string) {
    return function () {
      if (!isMemberSelected(memberId)) {
        onSelect([...props.selected, memberId]);
      } else {
        onSelect(props.selected.filter((id) => id !== memberId));
      }
    };
  }

  function isMemberSelected(memberId: string) {
    return selected.includes(memberId);
  }

  function handleLoadMore() {
    members$.querySuggested(
      groupId,
      {
        nickname: search,
      },
      members.length,
    );
  }

  function handleRetry() {
    members$.querySuggested(groupId, {
      nickname: search,
    });
  }
}

SuggestedMembersList.displayName = 'SuggestedMembersList';

function getEmptyStateKey(isSecret: boolean, search?: string) {
  if (isSecret) {
    return search
      ? 'groups-web.secret-group.members.empty-search-match.caption'
      : 'groups-web.discussion.members.empty';
  } else {
    return search
      ? 'group-web.members.empty-search-match.caption'
      : 'group-web.members.empty.caption';
  }
}
