import React from 'react';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { CloseSmall as CloseIcon } from '@wix/wix-ui-icons-common/on-stage';
import { Card } from 'wui/Card';
import { CardHeader } from 'wui/CardHeader';
import { CardContent } from 'wui/CardContent';
import { EmptyState } from 'wui/EmptyState';
import { IconButton } from 'wui/IconButton';
import { Button } from 'wui/Button';

import classes from './CentralFeed.scss';

interface IProps {
  onClick(): void;
}

export function InviteMembersCard(props: IProps) {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const [isClosed, setIsClosed] = React.useState(false);

  if (isClosed) {
    return null;
  }

  return (
    <Card sideBorders={!isMobile}>
      <CardHeader
        action={
          <IconButton
            className={classes.iconButton}
            icon={<CloseIcon />}
            onClick={handleCloseClick}
            aria-label={t('groups-web.a11y.close')}
          />
        }
      />
      <CardContent>
        <EmptyState
          wired
          variant="section"
          title={t('groups-web.invite-members-card.title')}
          subtitle={t('groups-web.invite-members-card.subtitle')}
          action={
            <Button onClick={props.onClick}>
              {t('groups-web.invite-members-card.cta')}
            </Button>
          }
        />
      </CardContent>
    </Card>
  );

  function handleCloseClick() {
    setIsClosed(true);
  }
}

InviteMembersCard.displayName = 'InviteMembersCard';
