import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { ListItem } from 'wui/ListItem';
import { ListItemText } from 'wui/ListItemText';
import { ListItemIcon } from 'wui/ListItemIcon';
import { Skeleton } from 'wui/Skeleton';

export function GroupListItemSkeleton() {
  const { isMobile } = useEnvironment();

  return (
    <ListItem>
      <ListItemIcon>
        <Skeleton variant="rect" width={40} height={40} />
      </ListItemIcon>
      <ListItemText
        title={<Skeleton variant="text" width={isMobile ? '50%' : '30%'} />}
        subtitle={<Skeleton variant="text" width={isMobile ? '55%' : '25%'} />}
      />
    </ListItem>
  );
}

GroupListItemSkeleton.displayName = 'GroupListItemSkeleton';
