import { createSelector } from 'reselect';

import {
  selectFeedPagination,
  selectIsSiteAdmin,
  selectJoinedGroups,
} from 'store/selectors';

export const selectHasActiveJoinedGroups = createSelector(
  selectJoinedGroups,
  (groups) =>
    groups.some((group) => group.membersCount && group.membersCount >= 10),
);

export const selectShouldShowInviteMembers = createSelector(
  selectIsSiteAdmin,
  selectHasActiveJoinedGroups,
  selectFeedPagination,
  (isSiteAdmin, hasActive, pagination) =>
    !pagination.nextCursor && !hasActive && isSiteAdmin,
);
