import React from 'react';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import {
  OverridableComponent,
  OverridableComponentProps,
} from '../OverridableComponent';
import { Box } from '../Box';

import classes from './CardActionArea.scss';

interface IProps extends TPAComponentProps {
  children?: React.ReactNode;
}

// eslint-disable-next-line react/display-name
export const CardActionArea = React.forwardRef(
  (props: OverridableComponentProps<IProps, typeof Box>, ref) => {
    const { children, ...rest } = props;

    return (
      <Box
        as="div"
        direction="vertical"
        role="button"
        tabIndex={0}
        onKeyDown={handleKeyDown}
        ref={ref}
        className={classes.root}
        {...rest}
      >
        {children}
        <span className={classes.opaque} />
      </Box>
    );

    function handleKeyDown(event: React.KeyboardEvent<HTMLElement>) {
      if (event.target !== event.currentTarget) {
        return;
      }

      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        props.onClick?.(event as unknown as React.MouseEvent<HTMLDivElement>);
      }
    }
  },
) as OverridableComponent<IProps, typeof Box>;

CardActionArea.displayName = 'wui/CardActionArea';
