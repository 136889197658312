import React from 'react';
import { useSelector } from 'react-redux';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import type { IGroup } from 'store/groups';
import { selectIsJoinedGroupMember } from 'store/selectors';

import { EImageRatio } from 'settings/constants';

import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';
import { GroupInfo } from 'common/components/GroupInfo';
import { GroupImage } from 'common/components/GroupImage';
import { RoleIcon } from 'common/components/RoleIcon';
import { GroupMembershipButton } from 'common/components/GroupMembership';

import { ListItem } from 'wui/ListItem';
import { ListItemText } from 'wui/ListItemText';
import { ListItemIcon } from 'wui/ListItemIcon';
import { ListItemButton } from 'wui/ListItemButton';
import { Box } from 'wui/Box';
import { Button } from 'wui/Button';
import { Typography } from 'wui/Typography';
import { ListItemAction } from 'wui/ListItemAction';
import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';

interface IGroupListItemProps extends TPAComponentProps {
  group: IGroup;

  onSelect(groupId: string): void;
}

export function GroupListItem(props: IGroupListItemProps) {
  const { group, onSelect, ...restProps } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { group$ } = useController();

  const isJoined = useSelector(selectIsJoinedGroupMember(group.id as string));

  useDidUpdate(() => {
    if (isJoined) {
      onSelect(group.id as string);
    }
  }, [isJoined]);

  return (
    <ListItem disablePadding actionOnHover={!isMobile} {...restProps}>
      <ListItemButton onClick={handleButtonClick}>
        <ListItemIcon>
          <Box width={40} height={40}>
            <GroupImage
              width={40}
              height={40}
              resize="cover"
              image={group.coverImage?.image}
              aspectRatio={EImageRatio.Square}
            />
          </Box>
        </ListItemIcon>
        <ListItemText
          title={
            <Box verticalAlign="middle">
              <Typography variant="p2-16" noWrap>
                {group.name}
              </Typography>
              <RoleIcon role={group.role} />
            </Box>
          }
          subtitle={<GroupInfo misc={isMobile} groupId={group.id as string} />}
        />
        <ListItemAction>
          <Show if={isJoined}>
            <Button variant="basic" tabIndex={-1} onClick={handleSelectClick}>
              {t('groups-web.select-group-modal.select')}
            </Button>
          </Show>
          <Hide if={isJoined}>
            <GroupMembershipButton
              tabIndex={-1}
              variant="basic"
              onClick={handleJoinClick}
              groupId={group.id as string}
            />
          </Hide>
        </ListItemAction>
      </ListItemButton>
    </ListItem>
  );

  function handleSelectClick(event: React.MouseEvent) {
    event.stopPropagation();
    onSelect(group.id as string);
  }

  function handleButtonClick() {
    if (isJoined) {
      onSelect(group.id as string);
    } else {
      group$.join({ groupId: group.id as string });
    }
  }

  function handleJoinClick(event: React.MouseEvent) {
    event.stopPropagation();
    group$.join({ groupId: group.id as string });
  }
}

GroupListItem.displayName = 'GroupListItem';
